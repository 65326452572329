// Example correction for importing an image and adjusting exports
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './index.css';
import Accueil from './pages/Accueil';
import Menu from './pages/Menu';
import JardinStRoch from './pages/JardinStRoch';
import DeltaHotel from './pages/DeltaHotel';
import Edifice511 from './pages/Edifice511';

// eslint-disable-next-line
const el = document.getElementById('root');

function AppRouter() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Accueil />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/jardin-st-roch" element={<JardinStRoch />} />
      <Route path="/edifice-511" element={<Edifice511 />} />
      <Route path="/delta-hotel" element={<DeltaHotel />} />
    </Routes>
  );
}

export default AppRouter; // Example of default export for single item
