import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';

interface ScheduleDisplayProps {
  dayContent: React.ReactNode;
  nightContent: React.ReactNode;
  dayStartTime: string; // Format 'HH:mm:ss'
  nightStartTime: string; // Format 'HH:mm:ss'
}

function ScheduleDisplay({
  dayContent,
  nightContent,
  dayStartTime,
  nightStartTime,
}: ScheduleDisplayProps) {
  const [isDay, setIsDay] = useState<boolean>(true);

  const checkTime = useCallback((): NodeJS.Timeout => {
    const timeNow = moment().tz('America/Montreal');

    const nightTime = moment(
      `${timeNow.format('YYYY-MM-DD')}T${nightStartTime}`,
    ).tz('America/Montreal');
    const dayTime = moment(
      `${timeNow.format('YYYY-MM-DD')}T${dayStartTime}`,
    ).tz('America/Montreal');

    const isCurrentlyDay = timeNow.isBefore(nightTime);
    setIsDay(isCurrentlyDay);

    const nextChangeTime = isCurrentlyDay ? nightTime : dayTime.add(1, 'days');
    if (nextChangeTime <= timeNow) {
      nextChangeTime.add(1, 'days');
    }

    const timeoutDuration = nextChangeTime.diff(timeNow);

    return setTimeout(checkTime, timeoutDuration);
  }, [dayStartTime, nightStartTime]);

  useEffect(() => {
    const timer = checkTime();
    return () => clearTimeout(timer);
  }, [checkTime]);

  return (
    <div className="h-screen w-full">{isDay ? dayContent : nightContent}</div>
  );
}

export default ScheduleDisplay;
