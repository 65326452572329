import { useEffect } from 'react';

interface AutoRefreshProps {
  intervalms: number;
}

function AutoRefresh({ intervalms }: AutoRefreshProps) {
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      window.location.reload();
    }, intervalms);

    return () => clearInterval(refreshInterval);
  }, [intervalms]);

  return null;
}

export default AutoRefresh;
