import React from 'react';
import blueCircle from '../img/blueCircles.svg';
import greenCircle from '../img/greenCircles.svg';

function AnimatedCircles() {
  return (
    <div className="relative flex h-32 w-32 items-center justify-center">
      <img
        src={blueCircle}
        alt="Circle"
        className="absolute h-full w-full animate-spin-slow"
      />
      <img
        src={greenCircle}
        alt="Circle"
        className="absolute h-3/4 w-3/4 animate-spin-reverse"
      />
    </div>
  );
}

export default AnimatedCircles;
