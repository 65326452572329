import React from 'react';
import logo from '../img/logo-stationnement-parkeo.png';
import Header from './Header';
import AnimatedCircles from './AnimatedCircles';

interface PriceScreenCenterProps {
  title: string;
  lines: { price: string; desc?: string }[];
}

function PriceScreenCenter({ title, lines }: PriceScreenCenterProps) {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-between border-[1vh] border-secondary bg-white p-6 font-custom text-primary">
      <Header title={title} logo={logo} />
      <div className="mt-8 w-full text-center">
        {lines.map((lineItem, index) => (
          <div className="mb-4">
            <p
              className={`font-semibold text-primary ${index === 0 ? 'text-[8vw]' : 'text-[5vw]'}`}
            >
              {lineItem.price}
            </p>
            {lineItem.desc && (
              <p
                className={`text-secondary ${index === 0 ? 'text-[5vw]' : 'text-[3vw]'}`}
              >
                {lineItem.desc}
              </p>
            )}
          </div>
        ))}
      </div>
      <p className="mb-4 self-start text-left text-[2vw] text-primary">
        Merci pour votre visite !!
      </p>
      <div className="absolute bottom-8 right-8">
        <AnimatedCircles />
      </div>
    </div>
  );
}

export default PriceScreenCenter;
